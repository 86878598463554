import React, {Component} from "react";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";
import Img from "gatsby-image";
import Section from "../Core/Section";
import Container from "../Core/Container";
import breakpoints from "../../styles/breakpoints";

const FormBlock = styled.div`
  padding: 2.25rem 1.25rem;
  color: var(--white);

  @media only screen and (min-width: ${breakpoints.lg}){
    padding: 4rem 1.25rem;
  }

  @media only screen and (min-width: ${breakpoints.xl}){
    padding: 6.375rem 1.25rem 4.563rem;
  }
`;

const HeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.25rem;
`;

const Icon = styled.img`
  margin-right: 0.625rem;
  height: 3rem;
`;

const Headings = styled.div`
  h3, p {
    margin: 0;
  }
`;

const Copy = styled.p`
  margin: 1.25rem 0 3.125rem;
`;

const Image = styled(Img)`
  height: 100%;
`;

const Form = styled.form`
  label {
    display: none;
  }
`;

const Bg = styled(Col)`
  background: var(--primary);
`;

const Button = styled.button`
  margin-bottom: 0.625rem;
  width: 100%;
  background: var(--secondary);
  color: var(--white);
  cursor: pointer;
  text-decoration: none;
  box-sizing: border-box;
  padding: 15px 8px 15px 8px;
  border: 1px solid var(--secondary);
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  display: block;
  transition: .3s;

  &:hover,
  &:focus {
    border-color: var(--lightenButton);
    background: var(--lightenButton);
    color: var(--white);
    transition: .3s;
  }
  
  &:disabled {
    border-color: var(--successGreen);
    background: var(--successGreen);
    transition: .3s;
  }
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--white);
  border-radius: 0.313rem;
  padding: 15px;
  outline: none;
  margin-bottom: 1.25rem;
  
  &::placeholder {
    color: #A2A2A2;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    font-size: 1rem;
  }
`;

const TC = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
  
  a {
    color: var(--white);
    margin-left: 0.313rem;
  }
`;

const SignCopy = styled.p`
  margin-bottom: 1.25rem;
`;

class FormImageBlock extends Component {
//const FormImageBlock = ({heading, subHeading, formCopy, image, icon}) => {

  constructor(params) {
    super(params)
    this.ref = React.createRef()
  }

  state = {
      sent: false,
  }

  submitEmail = (event) => {
    let app = this;
    event.preventDefault();
    let name = event.target.querySelector('#name').value,
        email = event.target.querySelector('#email').value;
    let url = `https://hooks.zapier.com/hooks/catch/421912/b57hn49/?email=${email}&name${encodeURIComponent(name)}`;
    
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
    xhr.send();

    setTimeout(() => {
      app.setState({sent: true});
      app.ref.current.querySelector('#name').value = "";
      app.ref.current.querySelector('#email').value = "";
    }, 1000);
  }

  render() {
    
    return (
    <Section id={this.props.id ? this.props.id : null}>
      <Container>
        <Row>
          <Bg md={6} lg={5}>
            <FormBlock>
              <HeadingRow>
                { this.props.icon && <Icon src={this.props.icon.image.asset.url} alt={this.props.icon.alt} />}
                <Headings>
                  {this.props.heading && <h3>{this.props.heading}</h3>}
                  {this.props.subHeading && <p>{this.props.subHeading}</p>}
                </Headings>
              </HeadingRow>
              <SignCopy>Become a member for free and enjoy exclusive deals and discounts on the biggest selection of UK
                properties. Please note iknow is part of the Snaptrip Group, and as such, you will receive
                communications from Snaptrip.</SignCopy>
              {this.props.formCopy && <Copy>{this.props.formCopy}</Copy> } 
              <Form name="email-signup" onSubmit={this.submitEmail} data-netlify-honeypot="bot-field" ref={this.ref}>
                <div>
                  <label htmlFor="name">Name</label>
                  <Input placeholder="Name" required="required" type="text" name="name" id="name" />
                </div>
                <div>
                  <label htmlFor="email">Email</label>
                  <Input placeholder="Email Address" required="required" type="email" name="email" id="email" />
                </div>
                <input type="hidden" name="form-name" value="email-signup" />
                <Button disabled={this.state.sent}>{ this.state.sent ? "Submitted!" : "Send me deals" }</Button>
                <TC>
                  We’ll email you from our parent brand <a href="https://www.snaptrip.com/" target="_blank">Snaptrip.com</a> - view the <a href="/privacy-policy">privacy policy</a>.
                </TC>
              </Form>
            </FormBlock>
          </Bg>
          <Col md={6} lg={7}>
            <Image fluid={this.props.image.image.asset.fluid} alt={this.props.image.alt} />
          </Col>
        </Row>
      </Container>
    </Section>
    );
  }
};

export default FormImageBlock;